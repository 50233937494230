import React from 'react'
import { graphql, navigate } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Artist from '../components/artist'
import SEO from '../components/seo'
import { toPlainText, isBrowser } from '../lib/helpers'
import ArtistProjectSlider from '../components/artist-project-slider'
import SingleViewHeroBlock from '../components/single-view-hero-block'
import ShowreelSliderBlock from '../components/showreel-slider-block'
import VideoBlock from '../components/video-block'
import PlaylistBlock from '../components/playlist-block'
import PlaylistSliderBlock from '../components/playlist-slider-block'
import SingleViewDescriptionBlock from '../components/single-view-description-block'
import FeaturedProjectsBlock from '../components/featured-projects-block'
import QuoteBlock from '../components/quote-block'
import HighlightBlock from '../components/highlight-block'
import ProjectsWithCreditsBlock from '../components/projects-with-credits-block'
import PDFDownloadBlock from '../components/pdf-download-block'
import RelatedProjectsSliderBlock from '../components/related-projects-slider-block'

export const query = graphql`
  query ArtistTemplateQuery($id: String!) {
    artist: sanityArtist(id: { eq: $id }) {
      id
      previewImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          id
        }
      }
      title
      slug {
        current
      }
      categories {
        __typename
        id
        title
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      hidePage
      isIndexed
    }
  }
`

const ArtistTemplate = props => {
  const { data, errors } = props
  const artist = data && data.artist
  const artistTitle = artist && artist.title
  const hidePage = artist && artist.hidePage
  const isIndexed = artist && artist.isIndexed

  const content = (artist._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case 'singleViewHeroBlock':
          el = (
            <SingleViewHeroBlock
              key={c._key}
              {...c}
              title={artist.title}
              categories={artist.categories}
              artist={artist}
            />
          )
          break
        case 'quoteBlock':
          el = <QuoteBlock key={c._key} {...c} />
          break
        case 'highlightBlock':
          el = <HighlightBlock key={c._key} {...c} />
          break
        case 'singleViewDescriptionBlock':
          el = <SingleViewDescriptionBlock key={c._key} {...c} artistTitle={artistTitle} />
          // el = () => <div>kokot</div>
          break
        case 'showreelSliderBlock':
          el = <ShowreelSliderBlock key={c._key} {...c} />
          break
        case 'videoBlock':
          el = <VideoBlock key={c._key} {...c} />
          break
        case 'playlistBlock':
          el = <PlaylistBlock key={c._key} {...c} />
          break
        case 'playlistSliderBlock':
          el = <PlaylistSliderBlock key={c._key} {...c} />
          break
        case 'featuredProjectsBlock':
          el = <FeaturedProjectsBlock key={c._key} {...c} />
          break
        case 'projectsWithCreditsBlock':
          el = <ProjectsWithCreditsBlock key={c._key} artistTitle={artistTitle} {...c} />
          break
        case 'pdfDownloadBlock':
          el = <PDFDownloadBlock key={c._key} {...c} />
          break
        case 'relatedProjectsSliderBlock':
          if (c.items?.length > 0) {
            el = <RelatedProjectsSliderBlock key={c._key} {...c} posterImage={false} />
          }
          break
        case 'artistProjectSlider':
          el = <ArtistProjectSlider key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {artist && !hidePage && (
        <SEO
          title={artist.title || 'Untitled'}
          description={toPlainText(artist._rawExcerpt)}
          noindex={!isIndexed}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {hidePage ? isBrowser() && navigate('/') : <Artist {...artist} content={content} />}
    </>
  )
}

export default ArtistTemplate
